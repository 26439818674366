

*, *::before, *::after{
    padding:0;
    margin:0;
    box-sizing: border-box;
}


:root{
    /* font-size: 62.5;   1rem always = 10px */

    /* ......COLOR VARIABLES..... */
    --darkBlue: rgb(30, 66, 88);
    --medBlue: rgb(65, 111, 140);
    --darkGrey: rgb(64, 76, 83);
    --lightGrey:#6a7d89;
    --tanGrey: #ededed;
    --tanGreyTrans: rgb(237, 237, 237, .7);
    --tanGreyDark: rgb(78, 78, 78);
    --white: rgb(205, 216, 221);
    --whiteTrans:rgba(205, 216, 221, 0.779);
    --red: rgb(193, 141, 141);
    --orange: rgb(255, 165, 0);
    --darkRed: rgb(195, 75, 75);
    --boxShadow: rgb(0, 0, 0, .375);
    --boxShadowClicked: rgb(0, 0, 0, .4);


    --defaultBorder: 2px solid var(--tanGreyDark);
    --borderThin: 1px solid var(--tanGreyDark);
}

html{
    scroll-behavior:smooth;

}

body{
    /* ........FONT........ */
    font-size:1.2rem;                      /* baseline font-size (1em) = 12px*/
    font-family: 'Rajdhani', sans-serif;
    position:relative;
    padding:0;
    
}

h1, h2{
    color:var(--darkBlue);
    letter-spacing: -1px;
}

h2{
    font-size:2em;
}

p{
    color:var(--darkGrey);
    font-weight:400;
    margin:0;
}


/* turn back to header being included here, undo margin-top adjustments */
.container{
    padding:2rem;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    background-color:var(--tanGrey); 
    max-width:none;
}



/* adjust vertical centering of sections to account for toolbar height - so they look more centered */
section:not(.previewer__section){
    margin-top:4rem;
}

/* section children with preceding sibling get a margin-top, also sub-sections' children with preceding sibling gets same margin-top */

.aboutme__section > *, .contact__section > * {
    margin-bottom:1rem;
} 

/* all <button> and <a> */
button, a{
    font-family: 'Rajdhani', sans-serif;
    text-transform: uppercase;
    font-weight: 400;;
    border:none;
    transition:all .1s ease-out;
    transition-delay: .05s;
    cursor: pointer;
    font-size:1.4em;
    
}

a, .hero__resumeBtn {
    padding: .75rem;
    background-color:var(--medBlue);
    display:inline-block;
    text-decoration:none;
}


/* ................ non-Nav <a> links.................. */

a:not(.nav__list__link), .hero__resumeBtn{
   color:white;
}

/* Mobile mode - no hover effect (margin change), but BG color still changes, and no :active state margin change either. MArgin change only for nav__list__links bc they have a box-shadow while unhovered  */
.nav__list__link:hover, .hero__btn:hover, .aboutme__link:hover, .footer__link:hover{
    background-color: rgb(30, 66, 88);
    color:white;
}

.footer__icon:hover svg{
    fill: var(--darkBlue);
}


.hero__btn:active, .aboutme__link:active, .previewer__btn:active, .footer__link:active{
    color:white;
    background-color: rgb(30, 66, 88);
    stroke: white;
    text-decoration:none; 
}



/* ....................................... HEADER ................................... */

header{
    position:fixed;
    display:flex;
    justify-content: flex-start;
    align-items: center;


    top:0;
    z-index:200;
    min-width:100%;
    padding:.75rem 1rem;
    background-color:var(--medBlue);
   
}

nav{
    display:block;
}


/* wrapper element <button> */
.nav__btn{
    display:flex;
}
.nav__svg{
    width:3rem;
    height:3rem;
}

.nav__svg{
    max-height:100%;           /*<------ svg does NOT overflow parent .nav__btn <button> */
    width:3rem;
    height:3rem;
    stroke:var(--tanGreyDark);
    stroke-width: 2;
  
}

.nav__btn:hover{
    cursor: pointer;
    background-color: white;
    color:var(--darkBlue);
}


/* ................... NAV BANNER (Nick Nall Designs) ............... */

.nav__h3{
    font-family: 'Sono', sans-serif;
    letter-spacing: -3px;
    position:absolute;
    margin:0;
    left:22%;
    min-width:max-content;
    /* border:1px solid red; */
    color:white;
    font-size:1.75rem;
   
}

/* ...............DROP-DOWN NAV / NAV MENU............. */

/* <ul> */
.nav__list{
   position:absolute;
   top:4.5rem;
   padding-left:0;     /*<-- undoing Bootstrap CSS (reboot.scss, line 167)*/
   list-style-type: none;
   display:none;

}

/* utility class to reveal <nav> */
.displayNav{
    display:inline-block; 
}

/* <li> */
.nav__list__item{
    margin-top:.6rem;
    max-width:max-content;
}

/* <a> */
.nav__list__link{
    display:inline-block;
    background-color: var(--white);
    box-shadow:.2em .2em var(--boxShadow);
    color:var(--tanGreyDark);
    font-size:1.5em;
    padding:10px;
    border:3px solid var(--tanGreyDark);
}


/* Mobile state-classes for nav <a> and all non-nav <a> */

/* for nav <a> navListLinks when they are clicked at mobile (includes movement bc default style has box-shadows)*/
.nav__list__link:active{
    color:white;
    background-color: rgb(30, 66, 88);
    box-shadow:.1em .1em var(--boxShadow);
    
    transform:translate(.15rem, -.15rem);
    text-decoration:none;


}

/* .....................................MAIN ..................................*/

/* ......................... HERO ......................... */

.hero__section{
    max-width:max-content;
    /* margin-top: 5rem; */
    align-self:center;
}

h1{
    font-size:2.5em;  
    font-family: 'Sono', sans-serif;;
    display:inline-block;
}

.hero__p{
    max-width:23rem;
}

/* ...... HERO BUTTONS .....*/

.hero__btnContainer{    
   max-width:max-content;
   margin-top:2rem;
   display:flex;
   flex-direction:column; /* changes to row @420px */
   gap:1.5rem;
}

.hero__btn{
    max-width:max-content;
}



/* ......................... PREVIEWER ......................... */

.dialog-container[aria-hidden="true"]{
    display:none;
}

.dialog-container, .dialog-overlay{
    z-index:249; /* to be above the <header> (z-index:200)*/
    position:fixed;
    top: 0; /* 1 */
    right: 0; /* 1 */
    bottom: 0; /* 1 */
    left: 0; /* 1 */
}

.dialog-overlay{
    background-color: rgb(43 46 56 / 0.9); /* 1 */
    animation: fade-in 200ms both;
}


.previewer__component{
    z-index:251;
    background-color: var(--white);
    border: 2px solid var(--tanGreyDark);
    border-radius: 20px;
    padding:1rem 1rem;
    position:relative;

    top:53%;
    left:50%;
    transform:translate(-50%, -50%);
    width:90%;
    max-width:1150px;
    min-height:max-content; 
    max-height:max-content; 

    animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;
}

/* .previewer__component & .dialog-overlay animations */
@keyframes fade-in {
    from {
      opacity: 0;
    }
}

@keyframes slide-up {
    from {
      transform: translate(10% 10%);
    }
}

@media (prefers-reduced-motion: reduce) {
    .dialog-overlay,
    .previewer__component {
      animation: none;
    }
}



.displayPreviewer{
    display:block; 
}

.previewer__section{
    display:flex;
    flex-direction: column; /* changes to row @520px */
    gap:.5rem;
}


/* ....................PREVIEWER BUTTON SECTION................ */


.button__section{
    margin-bottom:1rem;
}


.previewer__btn{
    display:flex;
    height: 2.5rem;
    border: 3px solid var(--tanGreyDark);
    align-items:center;
    max-width:min-content;
    gap:.4rem;
    background-color:var(--tanGrey);
    border: 1px solid var(--tanGreyDark);
    cursor:pointer;
}

/* for non-nav <a> "page links" (no mvmt bc don't have box-shadows by default)*/


.previewer__svg{
    background-color:transparent;
    height:2rem;
    width:2rem;
    stroke:var(--tanGreyDark);
    stroke-width: 3;
    transition:all .1s ease-out;
    transition-delay: .05s;
}

.previewer__btn span{    /* the word part of the btn */
    font-size:1em;
    font-weight: 500;
    color:var(--tanGreyDark);
    line-height:.8;;
    transition-delay: .05s;
}

.closeBtn{
    background-color: var(--red);
    padding:0rem;
    position:absolute;
    border-radius:50%;
    right:1rem;
}

/* ............Previewer Btn States............ */

/* Hover */

.previewer__btn:not(.closeBtn):hover{
    background-color:var(--darkBlue);
}

.closeBtn:hover{
    background-color:var(--darkRed);
}

.previewer__btn:not(.closeBtn):hover span{
    color:white;
} 

.previewer__btn:hover svg{
    stroke:white;
}

/* Click */

.previewer__btn:active{
    background-color: var(--darkBlue);
}

.previewer__btn:active span{
    color:white;
}

.previewer__btn:active svg{
    stroke:white;
}


.closeBtn:active {
    transform:scale(.7);
    background-color: var(--darkRed); 
}



/* .................PREVIEWER DOCUMENT SECTION.................... */

/* change out the document in Google Drive with another one with darker text */

.document__section{
    margin-left:auto;
    margin-right:auto;
    border:var(--borderThin);
    display:block; /*<-- not display:flex like sibling */
    height:100vw;
    max-height:70vh;
}

#iframe{
    display:flex;
    justify-content: center;
    min-width:100%; 
    min-height:100%; 
    /* max-height:40vh; */
}

/* ......................... ABOUT ME ......................... */

.aboutme__container{
    position:relative;
    isolation:isolate; /* so that ::after bg-img w/z-index:-1 still shows in container*/
}

.aboutme__container::after{
    background-image: url(img/desktop.png) ;
    background-size: cover;
    background-repeat: no-repeat;
    
    background-position:center; 
    min-height:100vh;
    content:'';
    z-index:-1;
    inset:0;
    position: absolute;
    opacity: .7;;
}


/* box component */
.aboutme__box{
    /* border:1px solid green; */
    border: var(--defaultBorder);
    background-color:var(--tanGrey);
    padding:2rem 2rem 1.5rem 2rem; 
    max-width:40rem;
    margin:0 auto;
}

.aboutme__p{
    color:black;
    font-weight:400;
}

.aboutme__btnContainer{
    margin-top:2rem;
    display:inline-block;
}

/* -------------- you stopped refactoring here ---------------- */

/* .................EXAMPLE WORK CONTAINER ...................*/


.examplework__container{
    padding-left:1rem;
    padding-right:1rem;
    padding-bottom:0; 
    justify-content:flex-start;
    
}

.examplework__section{
    /* border:2px solid blue; */
    height:87%;
    width:95%;
    max-width:80rem;
    text-align: center;
    display:flex;
    flex-direction:column;
    position:relative;
}

.examplework__h2{
    font-size: 2.5em;
    display:inline-block;
    margin:0;
    padding:.5rem .5rem 1rem .5rem;
    
}

/* 
In needed the .carousel to take up the remaining height inside of .examplework__section, but I couldn't use a percentage because .carousel shared the parent with the static-value height'd H2, so with a percentage setting, it only ever overflows or underflows. 

so I took the H2 out of the parent .examplework__section, at which point it allowed me to set .carousel to 100% of the .examplework__section.  

*/

/* container */
.carousel{
    flex-grow:1;
    border:1px solid var(--darkGrey);
    
}

.carousel-inner{
   height:100%;  
}

.carousel-control-next, 
.carousel-control-prev{
    transition:all .1s ease-out;
    background-color: var(--tanGreyTrans);
}

.carousel-control-next:hover, 
.carousel-control-prev:hover{
    /* position:static; */
    background-color: var(--tanGreyTrans);
}



/* NOTE; Bring these into the .carousel */
.carousel-control-next:active, 
.carousel-control-prev:active{
    /* position:static; */
    background-color: var(--tanGreyTrans);
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%6a7d89'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");

    /* background-image: none;; */
}

.carousel-control-next-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%6a7d89'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


.carousel-item{    /* <--- bootstrap class, undoing position:absolute*/
    background-size:cover;
    background-position:center top;

    position:static;      
    height:100%;
    overflow:hidden;
}

/* instead of just using 'desktop' picture for each slide... 
put 'mobile' version pictures in it initially, then, at some breakpoint, switch them all to a desktop version picture


*/

.carousel-item__1{
    background-image: url(img/devJobsMob.png);
}

.carousel-item__2{
    background-image: url(img/CarOneMob.png);
}

.carousel-item__3{
    background-image: url(img/CarTwoMob.png);
}

.carousel-item__4{
    background-image: url(img/CarThreeMob.png);
}

.carousel-item__5{
    background-image:url(img/CarFourMob.png);
}

.carousel-item__6{
    background-image:url(img/CarFiveMob.png);
}



.carousel-caption{
    background-color: var(--darkGrey);
    border:1px solid var(--white);
    right:.5rem;
    left:.5rem;
    bottom:1.5rem;
    margin:0 auto;
    padding:.75rem 1.5rem;
    max-width:300px;
    z-index:100;
}

.carousel-caption h5, .carousel-caption p{
    color: var(--tanGrey);
}

.carousel-caption h5{
    font-size:1.3em;
}


.carousel-caption p{
    font-size:.8em;
    text-align: left;
    margin-bottom:.5rem; 
}

.captionLink{
    padding:0 .5rem;
    background-color: unset;
    border:1px solid var(--white);
    font-size:1.25rem;
}

.captionLink:hover{
    background-color: white;
    color:var(--darkGrey);
}

.carousel-indicators{
    margin-bottom:0;
}


.carousel-indicators [data-bs-target] {
    background-color:var(--tanGreyDark);
}

/* .................CONTACT CONTAINER ...................*/

.contact__container{
    display:block;
}

.contact__section{
    display:flex;
    flex-direction:column;
}

#floatingTextArea{
    height:calc(100vh - 470px);
}

form >*:not(button){
    min-width:100%;
}

.form-control{
    font-size:1.2rem;
}

.icon{
    position:absolute;
    color:var(--darkBlue);
    top:1.35rem;
    left:1rem;
}

.form-floating label, .form-floating .form-control{
    left:.5rem;
    padding-left:3rem;
}

.btn{
    background-color:white;
    font-size:2rem;
    color:var(--darkBlue);
    border-color: var(--darkBlue);

}

.btn:hover{
    background-color: var(--darkBlue);
}



/* ....................... FOOTER .................... */

.footer__container{
    height:unset;
    padding-bottom:1rem;
}

.footer__section-2{
    margin:none;
}

.footer__section-1{
    min-width:100%;  
}

.row{
    margin:0;
    padding:0;
}

.row > * {
    padding:0;
    text-align:left;
    max-width:unset;
    margin-right:2rem;
}

.footer__link{
    margin-bottom:1rem;
    font-size:1.2rem;
}

.footer__title{
    font-family: 'Sono', sans-serif;
    font-weight: 300;
    font-size:1.5rem;
    word-spacing:-5px;
}


/* icon box */

.footer__icons{
    width:100%;
    max-width:250px;
    margin-right:auto;
    margin-left:auto;
}

.footer__icon{
    background-color:unset;
    padding:unset;
}


.footer__svg{
    height:2rem;
    width:2rem;
    fill:var(--medBlue);
    transition:all .1s ease-out;
    transition-delay: .05s;
}






/* ........................ MEDIA QUERIES ...................... */

/* ................... 325px BREAKPOINT............. */
@media(min-width:325px){
    /* ............ MAIN ........... */
    h1{
        font-size:3em; /* 37.5px */
    }
}

/* ................... 350px BREAKPOINT............. */
@media(min-width:350px){
    /* ........... HEADER ........... */
    .nav__list__link{
        font-size:1.75em;
    }
}

/* .................. 420px BREAKPOINT............... */
@media(min-width:420px){
    /* ...... GLOBAL ELEMENTS........ */


    .aboutme__section > *, .contact__section > * {
        margin-bottom:2rem;
    }

    /* ........... HEADER ........... */
    
    .nav__list__link{
        font-size:2em;
    }

    .nav__h3{
        font-size:2.25rem;
    }

   

    /* ............ MAIN ............ */

    .hero__btnContainer{
        margin-top:3rem;
        flex-direction:row;
    }

    .carousel-item{ 
        background-position:center top;
    }

    .carousel-item__1{
        background-image: url(img/devJobsDesk.png);
    }

    .carousel-item__2{
        background-image:url(img/CarOneDesk.png);
    }

    .carousel-item__3{
        background-image:url(img/CarTwoDesk.png);
    }

    .carousel-item__4{
        background-image:url(img/CarThreeDesk.png);
    }
    
    .carousel-item__5{
        background-image:url(img/CarFourDesk.png);
    }

    .carousel-item__6{
        background-image:url(img/CarFiveDesk.png);
    }


}

@media(min-width:560px){
    .previewer__section{
        flex-direction:row;
        gap:1.5rem;
    }

    

}

@media(min-width:590px){

    /* ......... HEADER ............. */
    .nav__h3{
        left:50%;
        transform:translatex(-50%);
        font-size:3rem;
    }
}






/* .................. 1300px DESKTOP BREAKPOINT............... */
@media(min-width:1300px){
    /* affects nav <a> (.nav__list__link) & <a> ('.btn' classes) */

    .nav__list__link:hover, .hero__btn:hover, .aboutme__link:hover, .previewer__btn:hover, .footer__link:hover{
        text-decoration: none;;
        color:white;
        transform:translate(-.15rem, -.15rem);
        box-shadow:.23em .23em var(--boxShadow);
        font-weight:600;
        background-color: var(--darkBlue);
    }

   .footer__icon:hover .footer__svg {
        transform:translate(-.15rem, -.15rem);
        /* text-shadow:.23em .23em var(--boxShadow); */
        filter: drop-shadow(.10em .10em var(--boxShadow));
        fill:var(--darkBlue);
    }

    .footer__icon:active .footer__svg{
        transform: translate(0);
        filter: drop-shadow(.05em .05em var(--boxShadow));
        
    }

    .previewer__btn:hover span{
        color:white;
    }

    .previewer__btn:hover svg{
        stroke:white;
    }

    .closeBtn:hover{
        background-color: var(--darkRed); 
        cursor:pointer;
    }


  

    /* ............active/click state............. */
    a:not(.captionLink):not(.footer__icon):active{
    /* color: white; */
    background-color: var(--darkBlue);
    transform:translate(0);   /*<-- move back to original position when clicked*/
    box-shadow:.1em .1em var(--boxShadow);
    }


    
   
    


    
}















