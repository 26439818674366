*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --darkBlue: #1e4258;
  --medBlue: #416f8c;
  --darkGrey: #404c53;
  --lightGrey: #6a7d89;
  --tanGrey: #ededed;
  --tanGreyTrans: #edededb3;
  --tanGreyDark: #4e4e4e;
  --white: #cdd8dd;
  --whiteTrans: #cdd8ddc7;
  --red: #c18d8d;
  --orange: orange;
  --darkRed: #c34b4b;
  --boxShadow: #00000060;
  --boxShadowClicked: #0006;
  --defaultBorder: 2px solid var(--tanGreyDark);
  --borderThin: 1px solid var(--tanGreyDark);
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  font-family: Rajdhani, sans-serif;
  font-size: 1.2rem;
  position: relative;
}

h1, h2 {
  color: var(--darkBlue);
  letter-spacing: -1px;
}

h2 {
  font-size: 2em;
}

p {
  color: var(--darkGrey);
  margin: 0;
  font-weight: 400;
}

.container {
  height: 100vh;
  background-color: var(--tanGrey);
  max-width: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

section:not(.previewer__section) {
  margin-top: 4rem;
}

.aboutme__section > *, .contact__section > * {
  margin-bottom: 1rem;
}

button, a {
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  font-family: Rajdhani, sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  transition: all .1s ease-out 50ms;
}

a, .hero__resumeBtn {
  background-color: var(--medBlue);
  padding: .75rem;
  text-decoration: none;
  display: inline-block;
}

a:not(.nav__list__link), .hero__resumeBtn {
  color: #fff;
}

.nav__list__link:hover, .hero__btn:hover, .aboutme__link:hover, .footer__link:hover {
  color: #fff;
  background-color: #1e4258;
}

.footer__icon:hover svg {
  fill: var(--darkBlue);
}

.hero__btn:active, .aboutme__link:active, .previewer__btn:active, .footer__link:active {
  color: #fff;
  stroke: #fff;
  background-color: #1e4258;
  text-decoration: none;
}

header {
  z-index: 200;
  min-width: 100%;
  background-color: var(--medBlue);
  justify-content: flex-start;
  align-items: center;
  padding: .75rem 1rem;
  display: flex;
  position: fixed;
  top: 0;
}

nav {
  display: block;
}

.nav__btn {
  display: flex;
}

.nav__svg {
  width: 3rem;
  height: 3rem;
  max-height: 100%;
  width: 3rem;
  height: 3rem;
  stroke: var(--tanGreyDark);
  stroke-width: 2px;
}

.nav__btn:hover {
  cursor: pointer;
  color: var(--darkBlue);
  background-color: #fff;
}

.nav__h3 {
  letter-spacing: -3px;
  min-width: max-content;
  color: #fff;
  margin: 0;
  font-family: Sono, sans-serif;
  font-size: 1.75rem;
  position: absolute;
  left: 22%;
}

.nav__list {
  padding-left: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 4.5rem;
}

.displayNav {
  display: inline-block;
}

.nav__list__item {
  max-width: max-content;
  margin-top: .6rem;
}

.nav__list__link {
  background-color: var(--white);
  box-shadow: .2em .2em var(--boxShadow);
  color: var(--tanGreyDark);
  border: 3px solid var(--tanGreyDark);
  padding: 10px;
  font-size: 1.5em;
  display: inline-block;
}

.nav__list__link:active {
  color: #fff;
  box-shadow: .1em .1em var(--boxShadow);
  background-color: #1e4258;
  text-decoration: none;
  transform: translate(.15rem, -.15rem);
}

.hero__section {
  max-width: max-content;
  align-self: center;
}

h1 {
  font-family: Sono, sans-serif;
  font-size: 2.5em;
  display: inline-block;
}

.hero__p {
  max-width: 23rem;
}

.hero__btnContainer {
  max-width: max-content;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
  display: flex;
}

.hero__btn {
  max-width: max-content;
}

.dialog-container[aria-hidden="true"] {
  display: none;
}

.dialog-container, .dialog-overlay {
  z-index: 249;
  position: fixed;
  inset: 0;
}

.dialog-overlay {
  background-color: #2b2e38e6;
  animation: .2s both fade-in;
}

.previewer__component {
  z-index: 251;
  background-color: var(--white);
  border: 2px solid var(--tanGreyDark);
  width: 90%;
  max-width: 1150px;
  min-height: max-content;
  max-height: max-content;
  border-radius: 20px;
  padding: 1rem;
  animation: .4s .2s both fade-in, .4s .2s both slide-up;
  position: relative;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translate(10% 10%);
  }
}

@media (prefers-reduced-motion: reduce) {
  .dialog-overlay, .previewer__component {
    animation: none;
  }
}

.displayPreviewer {
  display: block;
}

.previewer__section {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.button__section {
  margin-bottom: 1rem;
}

.previewer__btn {
  height: 2.5rem;
  border: 3px solid var(--tanGreyDark);
  max-width: min-content;
  background-color: var(--tanGrey);
  border: 1px solid var(--tanGreyDark);
  cursor: pointer;
  align-items: center;
  gap: .4rem;
  display: flex;
}

.previewer__svg {
  height: 2rem;
  width: 2rem;
  stroke: var(--tanGreyDark);
  stroke-width: 3px;
  background-color: #0000;
  transition: all .1s ease-out 50ms;
}

.previewer__btn span {
  color: var(--tanGreyDark);
  font-size: 1em;
  font-weight: 500;
  line-height: .8;
  transition-delay: 50ms;
}

.closeBtn {
  background-color: var(--red);
  border-radius: 50%;
  padding: 0;
  position: absolute;
  right: 1rem;
}

.previewer__btn:not(.closeBtn):hover {
  background-color: var(--darkBlue);
}

.closeBtn:hover {
  background-color: var(--darkRed);
}

.previewer__btn:not(.closeBtn):hover span {
  color: #fff;
}

.previewer__btn:hover svg {
  stroke: #fff;
}

.previewer__btn:active {
  background-color: var(--darkBlue);
}

.previewer__btn:active span {
  color: #fff;
}

.previewer__btn:active svg {
  stroke: #fff;
}

.closeBtn:active {
  background-color: var(--darkRed);
  transform: scale(.7);
}

.document__section {
  border: var(--borderThin);
  height: 100vw;
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#iframe {
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  display: flex;
}

.aboutme__container {
  isolation: isolate;
  position: relative;
}

.aboutme__container:after {
  min-height: 100vh;
  content: "";
  z-index: -1;
  opacity: .7;
  background-image: url("desktop.4e4343fd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.aboutme__box {
  border: var(--defaultBorder);
  background-color: var(--tanGrey);
  max-width: 40rem;
  margin: 0 auto;
  padding: 2rem 2rem 1.5rem;
}

.aboutme__p {
  color: #000;
  font-weight: 400;
}

.aboutme__btnContainer {
  margin-top: 2rem;
  display: inline-block;
}

.examplework__container {
  justify-content: flex-start;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.examplework__section {
  height: 87%;
  width: 95%;
  max-width: 80rem;
  text-align: center;
  flex-direction: column;
  display: flex;
  position: relative;
}

.examplework__h2 {
  margin: 0;
  padding: .5rem .5rem 1rem;
  font-size: 2.5em;
  display: inline-block;
}

.carousel {
  border: 1px solid var(--darkGrey);
  flex-grow: 1;
}

.carousel-inner {
  height: 100%;
}

.carousel-control-next, .carousel-control-prev {
  background-color: var(--tanGreyTrans);
  transition: all .1s ease-out;
}

.carousel-control-next:hover, .carousel-control-prev:hover, .carousel-control-next:active, .carousel-control-prev:active {
  background-color: var(--tanGreyTrans);
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%6a7d89'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%6a7d89'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-item {
  height: 100%;
  background-position: top;
  background-size: cover;
  position: static;
  overflow: hidden;
}

.carousel-item__1 {
  background-image: url("devJobsMob.e221ec14.png");
}

.carousel-item__2 {
  background-image: url("CarOneMob.168771f8.png");
}

.carousel-item__3 {
  background-image: url("CarTwoMob.0394ad7b.png");
}

.carousel-item__4 {
  background-image: url("CarThreeMob.18c1d3c3.png");
}

.carousel-item__5 {
  background-image: url("CarFourMob.1986a735.png");
}

.carousel-item__6 {
  background-image: url("CarFiveMob.d59ec6e2.png");
}

.carousel-caption {
  background-color: var(--darkGrey);
  border: 1px solid var(--white);
  max-width: 300px;
  z-index: 100;
  margin: 0 auto;
  padding: .75rem 1.5rem;
  bottom: 1.5rem;
  left: .5rem;
  right: .5rem;
}

.carousel-caption h5, .carousel-caption p {
  color: var(--tanGrey);
}

.carousel-caption h5 {
  font-size: 1.3em;
}

.carousel-caption p {
  text-align: left;
  margin-bottom: .5rem;
  font-size: .8em;
}

.captionLink {
  background-color: unset;
  border: 1px solid var(--white);
  padding: 0 .5rem;
  font-size: 1.25rem;
}

.captionLink:hover {
  color: var(--darkGrey);
  background-color: #fff;
}

.carousel-indicators {
  margin-bottom: 0;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--tanGreyDark);
}

.contact__container {
  display: block;
}

.contact__section {
  flex-direction: column;
  display: flex;
}

#floatingTextArea {
  height: calc(100vh - 470px);
}

form > :not(button) {
  min-width: 100%;
}

.form-control {
  font-size: 1.2rem;
}

.icon {
  color: var(--darkBlue);
  position: absolute;
  top: 1.35rem;
  left: 1rem;
}

.form-floating label, .form-floating .form-control {
  padding-left: 3rem;
  left: .5rem;
}

.btn {
  color: var(--darkBlue);
  border-color: var(--darkBlue);
  background-color: #fff;
  font-size: 2rem;
}

.btn:hover {
  background-color: var(--darkBlue);
}

.footer__container {
  height: unset;
  padding-bottom: 1rem;
}

.footer__section-2 {
  margin: none;
}

.footer__section-1 {
  min-width: 100%;
}

.row {
  margin: 0;
  padding: 0;
}

.row > * {
  text-align: left;
  max-width: unset;
  margin-right: 2rem;
  padding: 0;
}

.footer__link {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer__title {
  word-spacing: -5px;
  font-family: Sono, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.footer__icons {
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.footer__icon {
  background-color: unset;
  padding: unset;
}

.footer__svg {
  height: 2rem;
  width: 2rem;
  fill: var(--medBlue);
  transition: all .1s ease-out 50ms;
}

@media (min-width: 325px) {
  h1 {
    font-size: 3em;
  }
}

@media (min-width: 350px) {
  .nav__list__link {
    font-size: 1.75em;
  }
}

@media (min-width: 420px) {
  .aboutme__section > *, .contact__section > * {
    margin-bottom: 2rem;
  }

  .nav__list__link {
    font-size: 2em;
  }

  .nav__h3 {
    font-size: 2.25rem;
  }

  .hero__btnContainer {
    flex-direction: row;
    margin-top: 3rem;
  }

  .carousel-item {
    background-position: top;
  }

  .carousel-item__1 {
    background-image: url("devJobsDesk.c8992d71.png");
  }

  .carousel-item__2 {
    background-image: url("CarOneDesk.27479400.png");
  }

  .carousel-item__3 {
    background-image: url("CarTwoDesk.1729a97b.png");
  }

  .carousel-item__4 {
    background-image: url("CarThreeDesk.0a2fa8e0.png");
  }

  .carousel-item__5 {
    background-image: url("CarFourDesk.51b60ade.png");
  }

  .carousel-item__6 {
    background-image: url("CarFiveDesk.183baf6b.png");
  }
}

@media (min-width: 560px) {
  .previewer__section {
    flex-direction: row;
    gap: 1.5rem;
  }
}

@media (min-width: 590px) {
  .nav__h3 {
    font-size: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1300px) {
  .nav__list__link:hover, .hero__btn:hover, .aboutme__link:hover, .previewer__btn:hover, .footer__link:hover {
    color: #fff;
    box-shadow: .23em .23em var(--boxShadow);
    background-color: var(--darkBlue);
    font-weight: 600;
    text-decoration: none;
    transform: translate(-.15rem, -.15rem);
  }

  .footer__icon:hover .footer__svg {
    filter: drop-shadow(.1em .1em var(--boxShadow));
    fill: var(--darkBlue);
    transform: translate(-.15rem, -.15rem);
  }

  .footer__icon:active .footer__svg {
    filter: drop-shadow(.05em .05em var(--boxShadow));
    transform: translate(0);
  }

  .previewer__btn:hover span {
    color: #fff;
  }

  .previewer__btn:hover svg {
    stroke: #fff;
  }

  .closeBtn:hover {
    background-color: var(--darkRed);
    cursor: pointer;
  }

  a:not(.captionLink):not(.footer__icon):active {
    background-color: var(--darkBlue);
    box-shadow: .1em .1em var(--boxShadow);
    transform: translate(0);
  }
}

/*# sourceMappingURL=index.ea23b4c6.css.map */
